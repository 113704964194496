<template>
  <v-card>
    <v-card-title class="justify-space-between mb-0 py-8">
      <span class="text-h5"><strong>Doctors</strong></span>
    </v-card-title>
    <v-row class="ml-2 mr-2 mb-3">
      <v-col cols="12" md="3">
        <v-text-field v-model="filter.name" label="Name" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="3" class="pl-0">
        <v-text-field v-model="filter.email" label="Email" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="3" class="pl-0">
        <v-text-field v-model="filter.phone_number" label="Phone No." outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="3" class="pl-0 text-right">
        <v-btn color="primary" class="mr-2" @click="DoctorsSearchList()"> Search</v-btn>
        <v-btn color="primary"  @click="getDoctorsList()">Reset</v-btn>
      </v-col>
      <!-- <v-col cols="12" md="6">
        <v-btn color="primary" class="mr-8" @click="getDoctorsList()">Refresh</v-btn>
      </v-col> -->
    </v-row>

    <v-data-table :headers="headers" :items="doctorsList" :items-per-page="10" class="table-rounded" fixed-header
      height="400">
      <template #[`item.operate`]="{item}">
        <v-btn color="primary" class="mr-3" @click="editDoctorsList(item)"> Edit </v-btn>
        <EditDoctor :visible="editDoctor" :item="editItem" @close="editDoctor = false" />
        <v-btn color="error" text @click="deleteDoctor(item)"> Delete </v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="DeleteModal" max-width="550">
      <v-card>
        <v-card-title class="text-h5"> Delete confirmation </v-card-title>
        <v-card-text> Are you sure you want to delete ? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="DeleteModal = false"> Cancel </v-btn>
          <v-btn color="primary" @click="deleteDoctors(thisItemId)"> Confirm </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import axios from 'axios'
import DocotrsService from '@/service/Doctors.service'
import EditDoctor from '../Doctors/EditDoctors.vue'
const baseUrl = "https://api.stage.ebsr.in/doctor"
const localData = (JSON.parse(localStorage.getItem('token')) === null) ? window.location.href = '/' : JSON.parse(localStorage.getItem('token'));
const token = localData.token
export default {
  components: {
    EditDoctor,
  },
  data() {

    return {
      editItem: '',
      DeleteModal: false,
      thisItemId: '',
      headers: [
        { text: 'USER ID', value: 'id', width: '150px', align: ' d-none' },
        { text: 'NAME', value: 'doctor_name', width: '150px' },
        { text: 'EMAIL', value: 'email', width: '150px' },
        { text: 'PHONE NO.', value: 'phone_number', width: '150px' },
        { text: 'DISTRICT', value: 'district', width: '150px' },
        { text: 'HOSPITAL TYPE', value: 'hospital_type', width: '150px' },
        { text: 'HOSPITAL NAME', value: 'hospital_name', width: '150px' },
      ],

      doctorsList: [],
      addUserModal: false,
      editDoctor: false,
      snackbar: false,
      snackbarText: '',
      filter: {
        name: '',
        phone_number: '',
        email: '',
      },


    }
  },
  watch: {
    options: {
      handler() {
        this.getDoctorsList()
      },
      deep: true,
    },
  },

  async mounted() {
    this.getDoctorsList()
  },
  methods: {
    editDoctorsList(e) {
      this.editDoctor = true
      this.editItem = e
    },

    deleteDoctor(e) {
      this.DeleteModal = true
      this.thisItemId = e.id

    },


    async getDoctorsList() {
      this.listLoading = true
      const service = new DocotrsService()
      let response = await service.doctorsList()

      if (response) {
        this.doctorsList = response.data
        this.totallist = this.doctorsList.length
        this.filter.name = ""
        this.filter.email = ""
        this.filter.phone_number = ""
      } else {
        this.doctorsList = []
        this.totallist = 0
      }
      this.listLoading = false
    },

    async deleteDoctors(item) {
      const data = {
        id: item,
      }
      try {
        const response = await axios.post(`${baseUrl}/delete`, data, {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        if (response.data.status == 200) {
          this.snackbarText = response.data.message
          this.getDoctorsList()
          this.DeleteModal = false
        } else {
          this.snackbarText = response.data.message
        }
        this.snackbar = true
      } catch (e) {
        console.log(e)
      }
    },

    async DoctorsSearchList() {
      this.listLoading = true
      if (this.filter.name || this.filter.email || this.filter.phone_number) {
        const data = {
          phone_number: this.filter.phone_number,
          email: this.filter.email,
          name: this.filter.name,
        }
        try {
          const response = await axios.post(`${baseUrl}/search`, data, {
            headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          })
          if (response) {
            this.doctorsList = response.data.data
            this.totallist = this.doctorsList.length
          } else {
            this.doctorsList = []
            this.totallist = 0
          }
          this.listLoading = false
        } catch (e) {
          console.log(e)
        }
      } else {

        this.snackbarText = "Kindly Enter The Keyword"
        this.snackbar = true
        return
      }
    },


  }
}
</script>
